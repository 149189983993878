/* eslint-disable no-shadow */
export enum UsfProductCardModeEnum {
  addToListSearch = 'addToListSearch',
  bannerSearch = 'bannerSearch',
  cartReplacementSearch = 'cartReplacementSearch',
  defaultSearch = 'defaultSearch',
  exceptionSearch = 'exceptionSearch',
  replacementSearch = 'replacementSearch',
  listDetail = 'listDetail',
  listManagement = 'listManagement',
  promotions = 'promotions',
  recentlyPurchased = 'recentlyPurchased',
  reviewOrder = 'reviewOrder',
  tmNoteDetails = 'tmNoteDetails',
  addToInventory = 'addToInventory',
  addToInventorySearch = 'addToInventorySearch',
  compareProducts = 'compareProducts',
  worksWellWith = 'workswellwith',
  productNumberSearch = 'productNumberSearch',
  didYouForget = 'didYouForget',
  topSeller = 'topSeller',
  trendingNow = 'trendingNow',
  modifyVS = 'modifyVS',
}
