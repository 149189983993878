import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToggleNewSearchService {
  public static readonly NEW_SEARCH_ENABLED = 'toggle-new-search';
  private newSearchEnabled: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );

  constructor() {
    const enabled =
      sessionStorage.getItem(ToggleNewSearchService.NEW_SEARCH_ENABLED) ===
      'true';
    this.newSearchEnabled.next(enabled);
  }

  toggle() {
    const isToggleOn = !this.newSearchEnabled.value;
    sessionStorage.setItem(
      ToggleNewSearchService.NEW_SEARCH_ENABLED,
      isToggleOn.toString(),
    );
    this.newSearchEnabled.next(isToggleOn);
  }

  isNewSearchEnabled(): Observable<boolean> {
    return this.newSearchEnabled;
  }
}
