import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState, TokenAuthContext, TokenAuthOptions, UsfTokenStorageService } from '@panamax/app-state';
import { LoginActions } from '../store/actions/action-types';
import { CART_ACTIONS } from '@usf/ngrx-order';
import { LAST_SEARCH_TERM } from '@search/shared/constants/search-config';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  constructor(private store: Store<IAppState>,private usfTokenStorageService :  UsfTokenStorageService) {
    this.zipCode$ = new BehaviorSubject(this.zipCode);
  }

  zipCode$ : BehaviorSubject<number>;

  public set zipCode(v : number) {
    this.zipCode$.next(v);
  }
  
  logout(): void {
    this.store.dispatch(LoginActions.logout());
  }

  punchout(): void {
    this.store.dispatch(LoginActions.punchout());
  }

  //Updated to use new token services via a new LoginAction
  //TODO: settle on a good pattern here ... action vs dependency
  async switchToCustomer(
    customerNumber: number,
    departmentNumber: number,
    divisionNumber: number,
  ): Promise<void> {
    sessionStorage.removeItem('orderInCart');
    sessionStorage.removeItem(LAST_SEARCH_TERM);
    this.store.dispatch(
      LoginActions.refreshTokenWithContext({
        authContext: {
          customerNumber,
          departmentNumber,
          divisionNumber,
        } as TokenAuthContext,
      }),
    );
    this.store.dispatch(CART_ACTIONS.clearCart());
    return;
  }

  switchGuestZip(zipCode: number) {
    sessionStorage.removeItem(LAST_SEARCH_TERM);
    this.store.dispatch(LoginActions.switchGuestZipCode({ zipCode }));
  }
}
