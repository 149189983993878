import { Component, OnInit } from '@angular/core';
import { ToggleNewSearchService } from '@shared/services/toggle-new-search/toggle-new-search.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-toggle-new-search',
  templateUrl: './toggle-new-search.component.html',
  styleUrls: ['./toggle-new-search.component.scss'],
})
export class ToggleNewSearchComponent implements OnInit {
  toggleIsOn: Observable<boolean>;

  constructor(private toggleNewSearchService: ToggleNewSearchService) {}

  ngOnInit(): void {
    this.toggleIsOn = this.toggleNewSearchService.isNewSearchEnabled();
  }

  setToggle() {
    this.toggleNewSearchService.toggle();
  }
}
