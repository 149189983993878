import { PRONTO_ENABLED_FLAGS } from '@shared/constants/pronto-constants';
import { Customer } from '@usf/customer-types';

export const getProntoCutoffDayIndexes = (customer: Customer): number[] => {
  //pronto days have cutoff === 9
  const days = [
    customer?.sunCutoff === 9,
    customer?.monCutoff === 9,
    customer?.tueCutoff === 9,
    customer?.wedCutoff === 9,
    customer?.thuCutoff === 9,
    customer?.friCutoff === 9,
    customer?.satCutoff === 9,
  ];

  return days.reduce((accumulator: number[], isCutoffDay, index) => {
    if (isCutoffDay) {
      accumulator.push(index);
    }
    return accumulator;
  }, []);
};

export const isProntoEnabled = (customer: Customer): boolean => {
  return PRONTO_ENABLED_FLAGS.includes(customer?.prontoEnabledFlag);
};
